h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
}

h4 .spant {
    font-weight: bold;
    font-size: 18px;
    white-space: nowrap;
}

.left-stock-container {
    margin: 5px 0 0;
    font-weight: 500;
    font-size: 16px;
}

.left-stock-container .left-stock {
    color: #9E9E9E;
}

.oneProduct .product-name {
    text-align: left;
}

.rtl.oneProduct .product-name {
    text-align: right;
}

.oneProduct .category-name {
    text-align: left;
    height: 24px;
    min-width: 24px;
    line-height: 0;
    border-radius: 6px;
    cursor: default;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: capitalize;
    padding: 0px 6px;
    font-size: 0.75rem;
    font-weight: 700;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(0, 108, 156);
    background-color: rgba(0, 184, 217, 0.16);
    font-size: 13px;
    font-weight: 600px;
}

.rtl.oneProduct .category-name {
    text-align: right;
}

.oneProduct .description {
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0 5px;
    text-align: left;
}

.rtl.oneProduct .description {
    text-align: right;
}

.oneProduct .desc {
    color: #8A8A8A;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
}

.oneProduct .desc p {
    font-weight: normal;
    margin-bottom: 10px;
}

.oneProduct .available {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0 10px;
    text-align: left;
    display: inline-block;
}

.oneProduct .stock-available {
    color: #8A8A8A;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    margin-left: 5px;
}

/* .oneProduct .desc div {
    font-weight: normal;
    margin-bottom: 10px;
} */
.oneProduct .desc .wc-pao-addon-container.wc-pao-required-addon.wc-pao-addon {
    display: none;
}

.oneProduct .desc .elementor-element.elementor-widget.elementor-widget-heading {
    display: none;
}

.rtl.oneProduct .desc div h2 {
    text-align: right !important;
}

.oneProduct .desc table {
    font-size: 14px;
    color: black;
    border-color: #94a3b8;
}

.oneProduct .dark .desc {
    color: white;
}

.oneProduct .dark .desc table {
    color: white;
}

.oneProduct .desc table thead tr th,
.oneProduct .desc table tbody tr td {
    padding: 8px;
}

.oneProduct .quantity-container {
    color: black;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: fit-content;
    background-color: #EEE;
    border-radius: 20px;
    padding: 5px;
}

.oneProduct .quantity,
.oneProduct .qty-count {
    background: transparent;
    color: inherit;
    font-weight: bold;
    font-size: inherit;
    border: none;
    display: inline-block;
    min-width: 0;
    /* height: 2.5rem; */
    line-height: 1;
}

.oneProduct .quantity:focus,
.oneProduct .qty-count:focus {
    outline: none;
}

.oneProduct .quantity {
    width: 50px;
    min-width: 0;
    display: inline-block;
    text-align: center;
    appearance: textfield;
}

.oneProduct .qty-count {
    padding: 0;
    cursor: pointer;
    width: 2.5rem;
    font-size: 1.25em;
    text-indent: -100px;
    overflow: hidden;
    position: relative;
}

.oneProduct .qty-count::before,
.oneProduct .qty-count::after {
    content: "";
    height: 2px;
    width: 10px;
    position: absolute;
    display: block;
    background: black;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

/* .oneProduct .qty-count--minus {
    border-right: 1px solid #e2e2e2;
}

.oneProduct .qty-count--add {
    border-left: 1px solid #e2e2e2;
} */

.oneProduct .qty-count--add::after {
    transform: rotate(90deg);
}

.oneProduct .add-to-cart {
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 50%;
    border-radius: 20px;
}

.oneProduct .add-to-cart:hover {
    background-color: rgba(0, 0, 0, 0.795);
}

@media (max-width: 768px) {
    .oneProduct {
        background-color: #f1f4fb;
        margin: 0 !important;
    }

    .oneProduct .first-grid {
        padding-bottom: 0;
    }

    .oneProduct .white-container {
        background-color: white;
        margin-top: 20px;
        border-radius: 60px 60px 0 0;
    }

    .oneProduct .dark .white-container {
        background-color: #141313;
    }

    .oneProduct .category-name {
        text-align: left;
        font-size: 13px;
    }

    .oneProduct .product-name {
        font-size: 20px;
    }

    .oneProduct .spant {
        font-size: 15px;
    }

    .oneProduct .rating-container {
        font-size: 13px;
    }

    .oneProduct .rating {
        font-size: 15px;
    }

    .oneProduct .description {
        font-size: 14px;
        text-align: left;
    }

    .oneProduct .desc {
        font-size: 13px;
        text-align: left;
    }

    .oneProduct .desc table {
        font-size: 13px;
    }
}

@media (min-width: 901px) {
    .mobile1 {
        display: none;
    }
}

@media (max-width: 900px) {
    .mobile1 {
        display: block;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 350;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        padding: 5px;
        height: 60px;
        background-color: white;
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.12);
    }

    .mobile1 .add-to-cart-mobile {
        width: 100%;
    }

    .desktop {
        display: none;
    }
}

.elementor-element.elementor-add-to-cart--align-justify.elementor-add-to-cart--layout-stacked.elementor-widget.elementor-widget-wc-add-to-cart {
    display: none;
}