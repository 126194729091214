.cat-nav {}

.cat-nav .cat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}

.cat-nav .cat-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid #eee;
}

.swiper-option {
    width: 100%;
    height: 3vh;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.swiper-option .swiper-arrows {
    display: flex;
    align-items: center;
    gap: 10px;
}

.swiper-option .swiper-arrows .arrow {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 3px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.swiper-option .swiper-arrows .arrow:hover {
    background-color: #D62828;
    border-color: #D62828;
}

.swiper-option .swiper-arrows .swiper-button-disabled,
.swiper-option .swiper-arrows .swiper-button-disabled svg {
    border-color: #FFFFFF66;
    fill: #FFFFFF66;
    cursor: not-allowed;
}

.swiper-option .swiper-progress {
    width: 80%;
    height: 3px;
    background-color: #FFFFFF66;
    border-radius: 1rem;
}

.swiper-option .swiper-progress .swiper-scrollbar-drag {
    background-color: #D62828;
    height: 100%;
}

@media (max-width: 768px) {
    .swiper-option .swiper-progress {
        width: 100%;
    }
}

.cat-nav .cat-item button {
    width: 70px;
    height: 107px;
    position: absolute;
    opacity: 0.001;
    cursor: pointer;
    user-select: none;
}

.cat-nav .cat-name-title {
    font-size: 13px;
    font-weight: 600;
}

.cat-nav .cat-item .active {
    border: 1px solid black;
}

.cat-nav .dark.cat-item .active {
    border: 1px solid white;
}