.shipping .location-header {
    flex: 1;
    text-align: center;
    font-weight: bold;
    font-size: 16px
}

.shipping .go-back {
    font-size: 12px;
}

@media (max-width: 900px) {
    .shipping .location-header {
        font-size: 14px
    }

    .shipping svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
        font-size: 18px !important;
    }

    .delivery-container .delivery {
        font-size: 14px !important;
    }
}