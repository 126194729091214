.main-image-container {
    width: 550px;
}

.main-image-container .main-image {
    height: 498px;
    max-width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}

.image-slider-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-slider-container .image-slide {
    opacity: 0.5;
    cursor: pointer;
    height: 100px;
    width: 100px;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    max-width: 100%;
}

.image-slider-container .image-slide:hover {
    opacity: 1;
}

.image-slider-container .image-slide.active {
    opacity: 1;
}

@media (max-width: 768px) {
    .product-display {
        background-color: #f1f4fb;
        margin: 0 !important;

    }

    .product-display .first-grid {
        padding-bottom: 0;
    }

    .main-image-container {
        width: auto
    }

    .main-image-container .main-image {
        height: auto;
        margin: 10px 0;
    }

    .image-slider-container .image-slide {
        height: 90px;
        width: 90px;
    }
}