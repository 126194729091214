body {
  margin: 0;
  font-family: "Almarai", sans-serif !important;
  overflow-x: hidden;
}

code {
  font-family: "Almarai", sans-serif !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
button {
  margin: 0;
  text-transform: capitalize !important;
}

a {
  text-decoration: none;
}