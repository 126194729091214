.accordion-header {
    display: flex;
    padding: 6px 12px;
    margin: 10px 0;
    background-color: #f0f0f0;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    color: black;
    font-size: 16px;
}

.list-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}

.rtl .list-item {
    text-align: right;
}

.list-item:hover {
    background-color: #f0f0f0;
    border-left: 4px solid black;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rtl .list-item:hover {
    border-right: 4px solid black;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-left-color: transparent;
}

.hidden {
    display: none;
}

.searchBar {
    border: none;
    background-color: #eeeeee42;
    border-radius: 5px;
    padding: 5px 10px;
    width: 100%;
    height: 30px;
    font-size: 16px;
    outline: none;
}

.searchBar-container svg {
    width: 30px;
    height: 30px;
    padding: 5px;
}

@media (max-width: 900px) {
    .searchBar-container svg {
        width: 18px;
        height: 18px;
        padding: 8px;
    }

    .searchBar {
        height: 24px;
        font-size: 14px;
    }

    .accordion-header {
        font-size: 14px;
    }

    .accordion-header svg {
        width: 18px;
        height: 18px;
    }

    .list .list-item {
        font-size: 14px;
    }
}

.listsContainer {
    color: black;
}